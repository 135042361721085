import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";

export function ModalProgressBarAnimated({ variant = "", label="" }) {

    // let [progress, setProgress] = useState(now);
  
    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     if (progress < 100) {
    //       setProgress(progress + 10)
    //     }
    //   }, 20);
    //   return () => {
    //     clearTimeout(timer);
    //   };
    // }, [progress, setProgress])
  
    return (
      <ProgressBar
        label={label}
        variant={variant}
        striped={false}
        animated
        now={100}
        style={{ 
          height: "18px", 
          width: "100%",
          fontSize: "0.9rem",
          color:'white',
          fontWeight:'800',
          position: "absolute",
          top: "60px",
          borderRadius: 0,
          backgroundColor: "transparent !important",
          background: "linear-gradient(to right, #f40009, #e5813e, #6ac9ce)"
        }}
      />
    );
  }