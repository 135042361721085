import { useEffect, useState } from "react";
import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import { FC } from 'react'
import Select, { MultiValue, SingleValue, StylesConfig, components, GroupBase, MenuListProps } from "react-select";
import { useRunRedux } from '../../../app/hooks/APISelectHooks'

const setColor = () => {
    const themeMode = localStorage.getItem("kt_theme_mode_value");
    if (themeMode === "dark") {
        return "#1e1e2d"
    }
    if (themeMode === "light") {
        return "#ffffff"
    }
};

const setColorText = () => {
    const themeMode = localStorage.getItem("kt_theme_mode_value");
    if (themeMode === "light") {
        return "#1e1e2d"
    }
    if (themeMode === "dark") {
        return "#ffffff"
    }
};

const getSelect2CSSClasses = (touched: {}, errors: {}) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("border-danger");
    }
    if (touched && !errors) {
        classes.push("border-success");
    }
    return classes.join(" ");
};

// DOCS: https://react-select.com/styles#style-object
const customStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> | undefined = {
    //new block
    container: (base: {}, { selectProps: { isDisabled } }: { selectProps: { isDisabled: boolean } }) => ({
        padding: 0,
        height: "auto",
        // backgroundColor: isDisabled ? '#F3F6F9' : "#FFFFFF",
        backgroundColor: `${setColor()}`,
        // zIndex: 4,
        marginRight: '1px'
    }),
    //end of new block
    control: (base: {}, { selectProps: { isDisabled } }: { selectProps: { isDisabled: boolean } }) => ({
        ...base,
        borderColor: "inherit", // --> changed from '#E5EAEE' to "inherit" 
        borderRadius: "inherit", //--> new value
        borderStyle: "unset", //--> new value
        borderWidth: "1px", //--> new value
        minHeight: "calc(1.3em + 1.3rem + 2px)", //--> new value
        // backgroundColor: isDisabled ? '#F3F6F9' : "#FFFFFF", //--> new value
        backgroundColor: `${setColor()}`,
        boxShadow: "none",
    }),
    // new block
    placeholder: (styles: {}) => ({
        ...styles,
        position: 'absolute',
        color: `${localStorage.getItem("kt_theme_mode_value") === 'dark' ? '#ffffff' : '#000000'}`,
    }),
    singleValue: (styles: {}) => ({
        ...styles,
        color: `${setColorText()}`,
    }),
    menu: (styles: {}) => ({
        ...styles,
        zIndex: 3000,
        marginTop: '0px',
        border: "0px solid #E4E6EF",
        borderRadius: '0px',
        backgroundColor: `${setColor()}`,
    }),
    menuList: (styles: {}) => ({
        ...styles,
        margin: '0',
        padding: '0',
    }),
    option: (styles: {}) => ({
        ...styles,
        backgroundColor: "none",
        "&:hover": {
            backgroundColor: "#6ac9ce",
            color: "#000000"
        }
    }),
    // end of new block
    multiValue: (styles: {}) => ({
        ...styles,
        // fontSize: '100%', --> old value
        // borderRadius: 5, --> old value
        backgroundColor: '#e5edfa',
        alignItems: 'center',
    }),
    multiValueLabel: (styles: {}) => ({
        ...styles,
        fontSize: '100%',
        // borderRadius: 5, --> old value
        // padding: 3 --> old value
        whiteSpace: 'pre-line', //--> new value
    }),
    multiValueRemove: (styles: {}, state) => ({
        ...styles,
        // backgroundColor: '#FFBDAD',
        // color: '#DE350B',        
        ':hover': {
            // backgroundColor: '#dc3545', // --> old style
            backgroundColor: 'transperant', // --> new style
            color: 'white',
            cursor: 'pointer'
        },
        svg: {
            backgroundColor: '#aab0b9',
            borderRadius: "20px",
            margin: 'auto',
            marginRight: '1px',
            width: '1.1 rem',
            color: 'rgb(229 237 250)',
            ':hover': {
                // backgroundColor: '#dc3545', // --> old style
                backgroundColor: '#898e96', // --> new style
            }
        },
        display: state.isDisabled ? 'none' : undefined
    }),
    indicatorsContainer: () => ({
        svg: {
            backgroundColor: '#aab0b9',
            borderRadius: "20px",
            margin: 'auto',
            width: '15px',
            height: '15px',
            color: 'rgb(229 237 250)',
            ':hover': {
                // backgroundColor: '#dc3545', // --> old style
                backgroundColor: '#898e96', // --> new style
            }
        },
    }),
    loadingMessage: (base: {}) => ({
        ...base,
        backgroundColor: "#6ac9ce",
        color: "#000000"
    }),
    loadingIndicator: (base: {}, state: { isFocused: boolean }) => ({
        ...base,
        display: state.isFocused ? "block" : "none",
        color: "#464E5",
        fontSize: 5,
    }),
    // Here are right side idicators X and \/ . We can use each one separate or the hole Indicator Container!
    clearIndicator: (prevStyle: {}, state: { isMulti: boolean }) => state.isMulti ? ({
        ...prevStyle,
        display: 'none'
    }) : ({
        ...prevStyle
    }),
    indicatorSeparator: (prevStyle: {}) => ({
        ...prevStyle,
        display: 'none'
    }),
    dropdownIndicator: (prevStyle: {}) => ({
        ...prevStyle,
        display: 'none'
    }),
    input: (styles: {}) => ({
        ...styles,
        color: `${setColorText()}`,
        // color: "#ffff"
    }),
    // Set the hole Indicator container to None if Multi true!
    // indicatorsContainer: (prevStyle, state) => ({
    //     ...prevStyle,
    //     display: 'none'
    // }),
}


const lockFieldPlaceHolder = (isLoading: boolean, currentProp: string) => {
    return isLoading ? 'Data Loading...' : currentProp
}

const requiredFieldStyle = (req: boolean) => {
    return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}


const MenuList = function MenuList(props: JSX.IntrinsicAttributes & MenuListProps<unknown, boolean, GroupBase<unknown>>) {

    return (
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
    );
};

export interface APISelectInterface {
    field: {
        name: string,
        value: SingleValue<string | number> | MultiValue<string | number>
    },
    form: {
        touched: {},
        errors: {},
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: { value: string, label: string } | "" | string) => {},
        values: { jsonID: { onPremisesSamAccountName: string, mail: { value: string } } }
    },
    label: string,
    required: boolean,
    isLoading: boolean,
    disabled: boolean,
    help?: getFieldHelpInterface,
    type: string,
    multipleChoise: boolean,
    hidden: boolean,
    placeholder: string,
    setfields: Array<string>,
    endpoint: {
        function: (e: { args: string }) => { data: [{}], isFetching: boolean },
        scope: {
            stateObject: string,
            data: {
                value: string,
                label: string
            }
        }
    },
    editmode: boolean
}

type sortDataType = "graphdata"

export function isSortDataType(prop: string): prop is sortDataType {
    return ["graphdata"].includes(prop)
}

export const APISelect: FC<APISelectInterface> = ({
    field,
    required = true,
    isLoading,
    disabled,
    help,
    placeholder,
    setfields,
    form: { touched, errors, setFieldTouched, setFieldValue, values },
    label,
    multipleChoise = false,
    hidden = false,
    type = "select",
    endpoint,
    editmode,
    ...props
}) => {
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const [options, setOptions] = useState<{ value: string; label: string; }[]>([]);
    const [inputChars, setInputChars] = useState('');
    const { records, loadingData } = useRunRedux(endpoint.function, inputChars)


    // useCheckUserCreatedQuery(values.jsonID.onPremisesSamAccountName,{skip: !values.jsonID.onPremisesSamAccountName})
    useEffect(() => {
        const getSubsetData = endpoint.scope.data;

        if (records && records.length > 0) {
            const subsetUserData = records.filter((item: { [key: string]: string }) => item[getSubsetData.value] !== null).map((item: { [key: string]: string }) => {
                return {
                    value: item[getSubsetData.value], label: item[getSubsetData.label] + ' (' + item[getSubsetData.value] + ')'
                }
            })
            setOptions(subsetUserData)
        } // eslint-disable-next-line
    }, [records])
    type fieldValue = { value: string, label: string }

    return (
        <>
            {label && <label className="fw-bolder font-size-h4 label-custom mb-2" hidden={hidden}>{label}{requiredFieldStyle(required)}</label>}
            {
                <div className="input-group">
                    <Select
                        // cacheOptions={false}
                        isClearable={true}
                        // isHidden={hidden}
                        // menuIsOpen={true}
                        styles={customStyles}
                        className={getSelect2CSSClasses(touch, errorMessage)}
                        {...field}
                        {...props}
                        options={options}
                        noOptionsMessage={({ inputValue }) => !(inputValue.length > 3) ? 'Please start typing to get results.' : "There are no results."}
                        isLoading={loadingData}
                        inputId={field.name}
                        onChange={(e) => {
                            const localValue = e as fieldValue
                            setFieldTouched(`jsonID.${field.name}`, true);
                            setFieldValue(field.name, localValue ? localValue : "");
                            if (setfields) {
                                setfields.forEach(field => {
                                    const my: { [a: string]: string } = records.filter((item: {
                                        [a: string]: string
                                    }) => item[endpoint.scope.data.value] === localValue.value)[0]
                                    if (e) {
                                        setFieldTouched(`jsonID.${field}`, true);
                                        setFieldValue(`jsonID.${field}`, my[field]);
                                    } else {
                                        setFieldTouched(`jsonID.${field}`, false);
                                        setFieldValue(`jsonID.${field}`, "");
                                    }
                                })
                            }
                            setOptions([]);
                            setInputChars('');
                        }}
                        onMenuClose={() => setOptions([])}
                        onInputChange={endpoint ? (e) => {
                            setInputChars(e);
                        } : undefined}
                        onKeyDown={(ev) => {
                            if (ev.key === "Enter") {
                                ev.preventDefault();
                            }
                        }}
                        isMulti={multipleChoise}
                        components={{ MenuList }}
                        isDisabled={disabled}
                        placeholder={lockFieldPlaceHolder(isLoading, placeholder)}

                    />
                    <PopoverHelper help={help} />
                    {
                        touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
                    }
                </div>
            }
        </>
    );
}
