import { WithChildren } from '../../../_metronic/helpers/react18MigrationHelpers';
import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import isURL from 'validator/lib/isURL';
import { FC } from 'react'

const getFieldCSSClasses = (touched: {}, errors: {}) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

const lockFieldPlaceHolder = (isLoading: boolean, currentProp: string) => {
    return isLoading ? 'Data Loading...' : currentProp
}

const requiredFieldStyle = (req: boolean) => {
    return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

interface LinkWrapperInterface {
    inputLink: boolean,
    value: string | number | undefined,
    disabled: boolean
}

const LinkWrapper: FC<LinkWrapperInterface & WithChildren> = ({ inputLink, value, children, disabled }) => {
    const checkForValues = (disabled && value)

    return (
        inputLink ?
            <a href={checkForValues ? `${value}` : ""} target="_blank" rel="noreferrer noopener" onClick={(e) => !(checkForValues) && e.preventDefault()} className="w-100">
                {children}
            </a> :
            <>{children}</>
    )
}

export interface inputInterface {
    field: {
        name: string,
        value: string | number | undefined
    },
    form: {
        touched: {},
        errors: {},
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: string | number | Array<string | number>) => {},
        values: {}
    },
    label: string,
    help?: getFieldHelpInterface,
    placeholder: string,
    required: boolean,
    isLoading: boolean,
    disabled: boolean,
    type: string,
    hidden: boolean,
    inputLink: boolean,
    editmode: boolean
}

export const Input: FC<inputInterface> = ({
    field, // { name, value, onChange, onBlur }
    required = true,
    isLoading,
    disabled,
    help,
    placeholder,
    form: { touched, errors, setFieldTouched, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    type = "text",
    hidden = false,
    inputLink = false,
    editmode,
    ...props
}) => {

    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const checkURL = field.value ? isURL(field.value as string, { protocols: ['http', 'https'], require_tld: true, require_protocol: true, require_host: true, require_port: false, require_valid_protocol: true, allow_underscores: false, host_whitelist: [], host_blacklist: [], allow_trailing_dot: false, allow_protocol_relative_urls: false, allow_fragments: true, allow_query_components: true, disallow_auth: false }) : false;

    return (
        <>
            {label && <label className="fw-bolder font-size-h4 label-custom mb-2" hidden={hidden}>{label}{requiredFieldStyle(required)}</label>}

            {
                <LinkWrapper inputLink={inputLink || checkURL} value={field.value} disabled={disabled}>
                    <div className="input-group">
                        <input
                            type={type}
                            className={`${getFieldCSSClasses(touch, errorMessage)} ${(disabled && field.value && (inputLink || checkURL)) ? "input-link" : ""}`}
                            {...field}
                            // {...props}
                            hidden={hidden}
                            onChange={(e) => {
                                setFieldTouched(field.name, true);
                                setFieldValue(field.name, e.target.value);
                            }}
                            disabled={disabled}
                            placeholder={lockFieldPlaceHolder(isLoading, placeholder)}
                            autoComplete="off"
                            onKeyDown={(ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                }
                            }}
                        />
                        <PopoverHelper help={help} />
                        {
                            touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
                        }
                    </div>
                </LinkWrapper>
            }
        </>
    );
}
