import { FC } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { useLocation, useNavigate } from 'react-router-dom';

export interface BackButtonInterface {
  label: string
}


export const BackButton: FC<BackButtonInterface> = ({
  label
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { editTable, newRow, setEditTable, setNewRow } = useServiceRunsContext();
  const handleSubmit = () => {
    setEditTable(false);
    setNewRow(false);
    navigate(currentPath)
  }

  return (
    <>
      {(editTable || newRow) &&
        <button
          type='button'
          className='btn btn-color-success btn-danger text-white btn-sm me-5'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => handleSubmit()}
        >
          <div className="d-flex align-items-center">
            <span>{label}</span>
          </div>
        </button>
      }
    </>
  );
}