import React, { ReactNode, useEffect, useState } from "react";
import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import { FilterOptionsState } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { FC } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
// import Popper from '@mui/material/Popper';
// import type { } from "@material-ui/lab/themeAugmentation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const getAutocompleteCSSClasses = (touched: {}, errors: {}) => {
  const classes = ["form-control select-drop-down"];
  if (touched && errors) {
    classes.push("border-danger icon-danger");
  }
  if (touched && !errors) {
    classes.push("border-success icon-success");
  }
  return classes.join(" ");
};

const requiredFieldStyle = (req: boolean) => {
  return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

export interface AutocompleteInterface {
  field: {
    name: string,
    value: { [key: string]: string } | Array<{ [key: string]: string }>
  },
  form: {
    touched: {},
    errors: {},
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: { [key: string]: string } | Array<{ [key: string]: string }>) => {},
    values: { [key: string]: { [key: string]: { [key: string]: string } | Array<{ [key: string]: string }> } }
  },
  label: string,
  required: boolean,
  isLoading: boolean,
  disabled: boolean,
  help?: getFieldHelpInterface,
  hidden: boolean,
  options: Array<{ label?: string, value?: string, name?: string, email?: string }>,
  ismulti: boolean,
  allowClearAllContent: boolean,
  placeholder: string,
  noOptionsText: string,
  defaultValue: {},
  // addCustomOptions: boolean,
  disableCloseOnSelect: boolean,
  customErrorField: string,
  editmode: boolean
}

const LISTBOX_PADDING = 8; // px


function renderRow(props: ListChildComponentProps) {
  const { data, index, style, isScrolling } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  // if (isScrolling) return <li style={style}>loading...</li>;

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
          useIsScrolling
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// const StyledPopper = styled(Popper)({
//   [`& .${autocompleteClasses.listbox}`]: {
//     boxSizing: 'border-box',
//     '& ul': {
//       padding: 0,
//       margin: 0,
//     },
//   },
// });

export const AutocompleteComponent: FC<AutocompleteInterface> = ({
  field,
  required = true,
  isLoading,
  disabled,
  ismulti,
  allowClearAllContent = true,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  label,
  hidden = false,
  options,
  placeholder,
  noOptionsText = "No other options available",
  defaultValue,
  help,
  // addCustomOptions = false,
  disableCloseOnSelect = true,
  customErrorField,
  editmode
}) => {
  const themeMode = localStorage.getItem("kt_theme_mode_value");
  const autocompleteComponentTheme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            padding: 0,
            minHeight: 'calc(1.5em + 1.3rem + 2px)',
            height: 'auto',
            zIndex: "4",
          },
          inputRoot: {
            padding: '3px 8px !important',
          },
          inputFocused: {
            border: 'None'
          },
          input: {
            height: '1.46em',
            fontSize: '14.3px',
            color: `${themeMode === 'dark' ? '#ffffff' : '#bfbbd3'}`,
            '&::placeholder': {
              WebkitTextFillColor: `${themeMode === 'dark' ? '#ffffff' : '#000000'}`,
              letterSpacing: 'normal',
              opacity: 1
            }
          },
          paper: {
            width: "calc(100% + 1.5em + 1.3rem))",
            border: '1px solid #464E5F',
            borderRadius: '4px',
            marginTop: '0px',
            boxShadow: `0rem 0rem 0.5rem ${themeMode === 'dark' ? 'rgba(0, 0, 0, 0.7)' : '#bfbbd3'}`,
          },
          option: {
            fontFamily: 'Poppins, Helvetica, "sans-serif"',
            color: `${themeMode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.87)'}`,
            background: `${themeMode === 'dark' ? '#2d2d3c' : '#ffffff'}`,
            '&.MuiAutocomplete-option.Mui-focused': {
              backgroundColor: '#6ac9ce',
              color: '#000000',
            },
          },
          listbox: {
            background: `${themeMode === 'dark' ? '#2d2d3c' : '#ffffff'}`,
          },
          noOptions: {
            fontFamily: 'Poppins, Helvetica, "sans-serif"',
            textAlign: 'center',
          },
          popupIndicator: {
            display: 'none',
          },
          endAdornment: {
            marginLeft: '0.5rem',
          }
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            border: 'none',
            width: '100%',
            // boxSizing: "revert",
            '&.Mui-disabled': {
              background: '#eff2f5',
              color: '#464E5F',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            borderBottom: 'none',
            '&::before': {
              borderBottom: 'none',
              transition: 'none',
            },
            '&::after': {
              borderBottom: 'none',
              transition: 'none',
            },
            '&::hover:not(.Mui-disabled)::before': {
              borderBottom: 'none',
            },
            '&.Mui-disabled::before': {
              borderStyle: 'none',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: 'none',
            color: '#BFBBD3'
          },
          root: {
            '&.Mui-disabled': {
              background: `${themeMode === 'dark' ? '#2b2b40' : '#eff2f5'}`,
            },
          },
          // input: {
          //   color: '#ffffff',
          //   '&.Mui-disabled': {
          //     // WebkitTextFillColor: '#ff0000',
          //   },
          // }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            display: 'flex',
            minWidth: 0,
            border: 'none',
            height: 'auto',
            minHeight: 'calc(1.5em + 1.3rem + 2px - 12px)',
            boxSizing: 'border-box',
            backgroundColor: 'rgba(106, 206, 127, 0.2)',
            borderRadius: "5px",
            fontSize: "1rem",
            transition: 'none',
            fontFamily: 'inherit',
            margin: "2px",
            '&.Mui-disabled': {
              color: '#898E96',
              opacity: "1",
            },
          },
          label: {
            padding: "3px",
            paddingLeft: "6px",
            paddingRight: "3px",
            color: `${themeMode === 'dark' ? 'white' : 'default'}`,
            overflow: 'auto',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-line',
          },
          deleteIcon: {
            height: '100%',
            color: `${themeMode === 'dark' ? '#bfbfbf' : 'default'}`,
            width: "1.8rem",
            alignItems: 'center',
            boxSizing: 'border-box',
            margin: 0,
            padding: "3px",
            '&: hover': {
              color: `${themeMode === 'dark' ? 'white' : '#000000'}`,
            }
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: `${themeMode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.87)'}`
          }
        }
      }
    },
  });


  const selectAllOption = { name: "Select All", value: "select-all" };
  const errorMessage = getIn(errors, customErrorField ? customErrorField : field.name);
  const touch = getIn(touched, customErrorField ? customErrorField : field.name);
  const filter = createFilterOptions();
  const [value, setValue] = useState<Array<{ [key: string]: string }> | { [key: string]: string }>(ismulti ? [] : { name: "", value: '' })
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [optionsArray, setOptionsArray] = useState(options)

  useEffect(() => {
    setOptionsArray(options)
    // setValue(ismulti ? [] : { name: "", value: '' })
  }, [options]);

  useEffect(() => {
    const fieldName = field.name.replace("jsonID.", "")
    if (values.jsonID[fieldName] && Array.isArray(values.jsonID[fieldName])) {
      setValue(values.jsonID[fieldName])
    }
  }, [values.jsonID[field.name.replace("jsonID.", "")]]);

  // clear select all option on field reset from outside
  useEffect(() => {
    if (values.jsonID[field.name.replace("jsonID.", "")] && values.jsonID[field.name.replace("jsonID.", "")].length === 0) setAllSelected(false)
  }, [values.jsonID[field.name.replace("jsonID.", "")]]);

  return (
    <>
      {label && <label className="fw-bolder font-size-h4 label-custom mb-2" hidden={hidden}>{label}{requiredFieldStyle(required)}</label>}


      <div className="input-group">

        <ThemeProvider theme={autocompleteComponentTheme}>
          <Autocomplete
            multiple={ismulti}
            id={field.name}
            // open={true}
            filterSelectedOptions
            className={`${getAutocompleteCSSClasses(touch, errorMessage)} autocomplete-padding`}
            // options={addCustomOptions ? optionsArray : options} //Needed to show the new options added manually (which are not part of the orriginal array)
            options={optionsArray}
            value={value}
            // disableListWrap
            noOptionsText={noOptionsText}
            disableClearable={allowClearAllContent}
            // PopperComponent={StyledPopper}
            disableCloseOnSelect={disableCloseOnSelect}
            disabled={disabled}
            defaultValue={[options[0]]}
            ListboxComponent={ListboxComponent}
            onKeyPress={(event) => event.key === 'Enter' && event.preventDefault()}
            onChange={(event, newValue, action) => {
              if (newValue) {
                let currentOptions = newValue;
                // when select all is clicked all options are selected
                if (action === "selectOption" &&
                  Array.isArray(newValue) &&
                  newValue.filter((el: { [key: string]: string }) => el.value === "select-all").length > 0) {
                  currentOptions = options
                }
                // when allSelected is true (means that previously all options are selected) and select all is clicked we clear all options
                if (allSelected && Array.isArray(newValue) && newValue.filter((el: { [key: string]: string }) => el.value === "select-all").length > 0) {
                  currentOptions = []
                }
                if (currentOptions.length === options.length) {
                  setAllSelected(true)
                } else {
                  setAllSelected(false)
                }
                setFieldTouched(field.name, true)
                setFieldValue(field.name, currentOptions)
                setValue(currentOptions)
              }
            }}
            filterOptions={(opts, params) => {
              const filtered = filter(opts, params as FilterOptionsState<unknown>) as Array<{ [key: string]: string }>;
              return ismulti ? [selectAllOption, ...filtered] : filtered;
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue.toString();
              }
              if (typeof option === 'object' && Array.isArray(option)) {
                return ""
              }
              return option.name.toString()
            }}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={Array.isArray(value) && value.length > 0 ? "" : placeholder}
              />
            )}
            renderOption={(props, option, { selected }) => {
              const selectAllProps =
                option.value === "select-all" // To control the state of 'select-all' checkbox
                  ? { checked: allSelected }
                  : {};
              const rn = [
                props,
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                  />
                  {option.name}
                </React.Fragment>,
              ] as ReactNode
              return rn
            }}
          />
        </ThemeProvider>

        <PopoverHelper help={help} />
        {
          touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
        }
      </div>

    </>
  );
}
