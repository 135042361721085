export const ENV_INDICATOR_ENUM = {
    "localhost": {
        short: "LOC",
        color: "#ffd000",
    },
    "development": {
        short: "DEV",
        color: "#6ace7f",

    },
    "testing": {
        short: "TEST",
        color: "#6ac9ce",

    },
    "production": {
        short: "PROD",
        color: "#e5813e",
    },
}