/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useGetAllServiceRunsQuery, useGetServiceConfigQuery } from '../modules/ServiceRuns/redux/cloudAppAPI'
import { useAuthRedux } from '../modules/auth/redux/useAuthRedux'
import { useSetErrorMessage } from "../hooks/useSetErrorMessage"
import { useWindowFocus } from '../../app/hooks/useWindowFocus'
import { useServiceRedux } from '../../app/modules/ServiceRuns/redux/useServiceRedux'
import { useGlobalContext } from '../../app/components/GlobalContext/GlobalContext'
import { Menu } from './Menu'

const HomePage: FC = () => {
  const [currentDateRange, setCurrentDateRange] = useState({ dateRange: JSON.stringify([new Date(new Date().setDate(new Date().getDate() - 1)), new Date()]) });
  const { showExpiringModal } = useAuthRedux();
  const { setLoaderMessage } = useGlobalContext();
  const { serviceConfigNames } = useServiceRedux();
  const skipQuery = showExpiringModal ? true : false;
  const windowFocused = useWindowFocus();
  const { error } = useGetAllServiceRunsQuery(currentDateRange, {
    skip: skipQuery,
    pollingInterval: windowFocused ? 60000 : 0,
    refetchOnFocus: true
  })
  const { error: configError } = useGetServiceConfigQuery({}, {
    skip: skipQuery,
    pollingInterval: windowFocused ? 30000 : 0,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })
  const isProd = process.env.REACT_APP_BACKEND_ENDPOINT?.includes("prod")

  /** use for mock testing, delete afterwards */
  // const test4e = "http://127.0.0.1:8000/api/"
  // const test4e = "https://nsr-latam-dev.coke.com/api/"
  // const test4e = "https://nsr-ssdp-api-latam-uat.coke.com/api/"
  // const test4e = "https://nsr-ssdp-api-apac-prod.coke.com/api/"
  // const isProd = test4e.includes("prod")
  /** */

  const getEnvironmentProps = () => {
    const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT!

    /** use for mock testing, delete afterwards */
    // const backendEndpoint = "http://127.0.0.1:8000/api/"
    // const backendEndpoint = "https://nsr-latam-dev.coke.com/api/"
    // const backendEndpoint = "https://nsr-ssdp-api-latam-uat.coke.com/api/"
    // const backendEndpoint = "https://nsr-ssdp-api-apac-prod.coke.com/api/"
    /** */

    const envProps: { env?: string; region?: string; } = {};
    envProps.region = backendEndpoint.startsWith("http://") ? "LOCAL" : backendEndpoint.includes("dev") ? "LATAM" : backendEndpoint.split('-')[3].toUpperCase()
    if (backendEndpoint.includes("dev")) {
      envProps.env = "development"
    } else if (backendEndpoint.includes("uat")) {
      envProps.env = "testing"
    } else if (backendEndpoint.includes("prod")) {
      envProps.env = "production"
    } else {
      envProps.env = "localhost"
    }
    return envProps
  }

  // const featuresData = [
  //   {
  //     cardTitle: "Request Services",
  //     cardLink: "/menu",
  //     // cardBody: "Request User Access for NSR Portal",
  //     cardLogoClasses: "fas fa-user-gear text-dark bg-homepage-logo",
  //     showInProd: true
  //   },
  //   // {
  //   //   cardTitle: "Run CubeRefresh Launcher",
  //   //   cardLink: "/services/createservicerun/CubeRefreshLauncher",
  //   //   // cardBody: "Run CubeRefresh Launcher",
  //   //   cardLogoClasses: "fas fa-cube text-dark fa-stack-5x bg-homepage-logo",
  //   //   showInProd: false
  //   // },
  //   // {
  //   //   cardTitle: "Financial View Sync",
  //   //   cardLink: "/services/createservicerun/FinancialViewSync",
  //   //   // cardBody: "Run Financial View Sync",
  //   //   cardLogoClasses: "fas fa-solid fa-group-arrows-rotate text-dark fa-stack-5x bg-homepage-logo",
  //   //   showInProd: false
  //   // },
  //   // {
  //   //   cardTitle: "Transaction Flat File Generation",
  //   //   cardLink: "/services/createservicerun/FlatFileGenerator",
  //   //   // cardBody: "List Requested Services",
  //   //   cardLogoClasses: "fas fa-regular fa-file text-dark bg-homepage-logo",
  //   //   showInProd: true
  //   // },
  //   {
  //     cardTitle: "List Requested Services",
  //     cardLink: "/services/listservicerun",
  //     // cardBody: "List Requested Services",
  //     cardLogoClasses: "fas fa-regular fa-rectangle-list text-dark bg-homepage-logo",
  //     showInProd: true
  //   }
  // ]

  useSetErrorMessage([
    { error: configError, function: "ServiceConfig" },
    { error: error, function: "AllServiceRuns" },
  ])
  useEffect(() => {
    serviceConfigNames === undefined ? setLoaderMessage("Loading Configuration data...") : setLoaderMessage("")
  }, [serviceConfigNames])

  return (
    <>
      <div className='homepage'>
        {/* {loaderMessage !== "" && <ModalProgressBarAnimated label={loaderMessage} />} */}
        <div className="homepage-bg"
          style={{ backgroundImage: `url('/media/dashboard/homepage_background.jpeg')` }} >
          <div className={!isProd ? 'background-text' : 'background-text-no-header'}>
            {/* <div className="text-white mt-10 mb-5">{!isProd ? `[${getEnvironmentProps().env?.toUpperCase()}]` : ""}</div> */}
            <div className="text-white">Welcome to</div>
            <div className="text-white">NSR {getEnvironmentProps().region} Services Portal</div>
          </div>
        </div>
        <div className='d-flex flex-column flex-grow-1'>
          {/* {(isProd ? featuresData.filter(feature => feature.showInProd === true) : featuresData)
            .map(feature => {
              const isActive = serviceConfigNames !== undefined || feature.cardLink.split('/')[feature.cardLink.split('/').length - 1] === "listservicerun"

              return (

                <Link to={isActive ? feature.cardLink : '#'} key={feature.cardTitle} className={`${isActive ? 'text-hover-primary' : ""} homepage-card-link`}>

                  <div className='card text-center homepage-card-container'>
                    <i className={`${feature.cardLogoClasses} ${isActive ? '' : "text-muted"}`}></i>
                    <h3 className={`card-title fw-bold bg-homepage-card-text ${isActive ? '' : "text-muted"}`}>
                      {feature.cardTitle}
                    </h3>
                  </div>
                </Link>

              )
            }
            )} */}
          <Menu />
          <div className='flex-grow-1 homepage-background'></div>
        </div>
      </div>
    </>
  )
}

export { HomePage }

